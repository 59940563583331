import React from 'react';
import SuggestedCandidate from './SuggestedCandidate';
import { ScoredCandidate } from './SuggestedCandidatesProvider';

type SuggestedCandidateResultsProps = {
  candidates: ScoredCandidate[];
  onSelect: (userId: string, userScore: number) => void;
};

/**
 * Renders a list of suggested candidates
 *
 * @param param0.candidate - the candidates to render
 * @param param0.onSelect - the callback handler for when the card is selected
 */
export default function SuggestedCandidateResults({
  candidates,
  onSelect,
}: SuggestedCandidateResultsProps) {
  return (
    <div className="grid grid-cols-2 gap-x-4 xl:grid-cols-4 w-9/10">
      {candidates.map(candidate => (
        <SuggestedCandidate
          onSelect={onSelect}
          candidate={candidate}
          key={candidate.id}
        />
      ))}
    </div>
  );
}
