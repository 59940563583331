import React from 'react';
import { useParams } from 'react-router';
import { checkmarkCircle, closeCircle } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import useWork from '../../../hooks/useWork';
import useUser from '../../../hooks/useUser';
import { LoadingBalls } from '../../core/Loading';
import { Link } from '../../core/router';
import { appUrl } from '../../../utilities/Url';
import useChatServices from '../../chat/hooks/useChatServices';
import { IChannel } from '../../../@types/chat.d';
import Api from '../../work/Api';
import { addError, addSuccess } from '../../../services/Messaging';
import ScoreAvatar from '../../candidateScore/ScoreAvatar';

type SidebarProps = {
  userId: string;
  userScore: number;
};

type InviteMutation = {
  workId: string;
  userId: string;
};

/**
 * Renders the RHS widget for the Suggested Candidates page when a candidate is clicked on.
 *
 * This component is responsible for presenting the different actions that a user can take with
 * regards to the candidate that it's currently displaying
 *
 * @param param0.userId - The identity of the user who is currently displayed in the RHS widget
 * @param param0.userScore - The matching score of the user based on the work post
 */
export default function Sidebar({ userId, userScore }: SidebarProps) {
  const { workId } = useParams<{ workId: string }>();
  const work = useWork(workId);
  const query = useUser(userId);
  const { checkForExistingChannel, createChannel } = useChatServices();
  const { mutate } = useMutation<InviteMutation, AxiosError, InviteMutation>(
    variables => Api.invite(variables.workId, { userId: variables.userId })
  );

  if (query.isLoading) {
    return (
      <div className="flex flex-col bg-white p-3 justify-between w-3/12 h-1/3 relative -right-3 ml-5 mt-4">
        <div style={{ height: '60vh' }} className="bg-white pt-20">
          <LoadingBalls isActive />
        </div>
      </div>
    );
  }

  const role = query.user.roles[0].role.name;
  const name = `${query.user.firstName} ${query.user.lastName}`;
  const location = query.user.location?.friendlyName ?? '';
  const { avatarURL } = query.user;

  const workSkills = work.data?.skills.map(workSkill => workSkill.name);
  const userSkills = query.user.skills.map(userSkill => userSkill.skill.name);

  const generateInviteMessage = () => {
    const inviteLink = appUrl(`/work/${workId}?t=${userId}:lk`);
    const message = 'Thought you might be perfect for this position.';
    return `${message}\n\n${inviteLink}`;
  };

  const sendInviteToNewChannel = async (message: string) => {
    await createChannel({
      identities: [userId],
      message,
    });
  };

  const sendInviteToChannel = async (channel: IChannel, message: string) => {
    await channel.sendMessage(message);
  };

  const sendInviteLink = async (message: string) => {
    const existingChannel = await checkForExistingChannel(userId);

    if (existingChannel) {
      sendInviteToChannel(existingChannel, message);
    } else {
      sendInviteToNewChannel(message);
    }
  };

  const updateCandidateStatus = () => {
    mutate(
      { workId, userId },
      {
        onSuccess: () => addSuccess('Successfully invited the candidate'),
        onError: error => {
          Sentry.captureException(error);
          addError(
            'An error occurred while inviting the candidate. Please try again.'
          );
        },
      }
    );
  };

  const onInvite = async () => {
    const inviteMessage = generateInviteMessage();
    await sendInviteLink(inviteMessage);
    updateCandidateStatus();
  };

  return (
    <div className="flex flex-col bg-white p-3 justify-between w-3/12 h-1/3 relative -right-3 ml-5 mt-4">
      <div className="h-5/6 my-8 flex flex-col text-center">
        {avatarURL && (
          <div className="justify-center mb-9 flex">
            <ScoreAvatar
              name={name}
              imageUrl={avatarURL}
              percentage={userScore}
              size="32"
            />
          </div>
        )}
        <div>
          <h5 className="font-bold">{name}</h5>
          <p>{role}</p>
          <p>{location}</p>
        </div>
        <button
          type="button"
          id="invite-to-apply-suggested-candidate"
          className="my-5 btn btn--sm btn--primary btn--filled"
          onClick={onInvite}
        >
          Invite to Apply
        </button>
        <hr />
        <div className="grid grid-cols-6 justify-items-start my-8">
          <div className="col-span-6">
            <h6>Skills Match</h6>
          </div>
          {workSkills?.map(skill => (
            <>
              <div className="col-span-5">{skill}</div>
              <div className="col-span-1">
                {userSkills.includes(skill) ? (
                  <IonIcon
                    className="fill-green-1100"
                    size="small"
                    icon={checkmarkCircle}
                  />
                ) : (
                  <IonIcon
                    className="fill-red-1100"
                    size="small"
                    icon={closeCircle}
                  />
                )}
              </div>
            </>
          ))}
        </div>
        <div>
          <Link
            to={`/members/user/${userId}`}
            className="btn btn--primary btn--outline btn--sm w-full"
          >
            View Profile
          </Link>
        </div>
      </div>
    </div>
  );
}
