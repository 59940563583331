import React, { useState } from 'react';
import SuggestedCandidateResults from './SuggestedCandidateResults';
import Sidebar from '../sidebar/Sidebar';
import { ScoredCandidate } from './SuggestedCandidatesProvider';

type SearchListProps = {
  suggestedCandidates: ScoredCandidate[];
};

/**
 * Returns the list of filtered suggested candidates
 * @param param0.suggestedCandidates - the candidates to display
 * @param param0.loading - loading state of the filter
 */
export default function SuggestedCandidateSearchList({
  suggestedCandidates,
}: SearchListProps) {
  const [clickedUserId, setClickedUserId] = useState('');
  const [clickedUserScore, setClickedUserScore] = useState(0);

  function handleSelect(userId: string, userScore: number) {
    setClickedUserId(userId);
    setClickedUserScore(userScore);
  }

  return (
    <section>
      <main className="flex mx-auto w-9/10">
        {suggestedCandidates && suggestedCandidates.length > 0 ? (
          <>
            <SuggestedCandidateResults
              onSelect={handleSelect}
              candidates={suggestedCandidates}
            />
            {!!clickedUserId && (
              <Sidebar userId={clickedUserId} userScore={clickedUserScore} />
            )}
          </>
        ) : (
          <p className="justify-self-center">
            Sorry, we are unable to find candidates that are qualified at this
            point in time. Please try again later.
          </p>
        )}
      </main>
    </section>
  );
}
